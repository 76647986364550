const requireAll = (o) => {
  Object.keys(o).forEach((k) => {
    if (o[k] == null) {
      throw new Error(`Key ${k} is missing from config`)
    }
  })
  return o
}

const StorybookStub = {
  GATSBY_CONTENTFUL_HOST: "Stub",
  GATSBY_CONTENTFUL_SPACE_ID: "Stub",
  GATSBY_CONTENTFUL_ACCESS_TOKEN: "Stub",
  GATSBY_CONTENTFUL_GRAPHQL_ENDPOINT: "Stub",
  GATSBY_SHOPIFY_STOREFRONT_API_KEY: "Stub",
  GATSBY_SHOPIFY_STOREFRONT_API_ENDPOINT: "Stub",
  GATSBY_SHOPIFY_ADMIN_API_KEY: "Stub",
  GATSBY_SHOPIFY_ADMIN_API_ENDPOINT: "Stub",
}

const Config = {
  GATSBY_CONTENTFUL_HOST: process.env.GATSBY_CONTENTFUL_HOST,
  GATSBY_CONTENTFUL_SPACE_ID: process.env.GATSBY_CONTENTFUL_SPACE_ID,
  GATSBY_CONTENTFUL_ACCESS_TOKEN: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
  GATSBY_CONTENTFUL_GRAPHQL_ENDPOINT:
    process.env.GATSBY_CONTENTFUL_GRAPHQL_ENDPOINT,
  GATSBY_SHOPIFY_STOREFRONT_API_KEY:
    process.env.GATSBY_SHOPIFY_STOREFRONT_API_KEY,
  GATSBY_SHOPIFY_STOREFRONT_API_ENDPOINT:
    process.env.GATSBY_SHOPIFY_STOREFRONT_API_ENDPOINT,
  GATSBY_SHOPIFY_ADMIN_API_KEY: process.env.GATSBY_SHOPIFY_ADMIN_API_KEY,
  GATSBY_SHOPIFY_ADMIN_API_ENDPOINT:
    process.env.GATSBY_SHOPIFY_ADMIN_API_ENDPOINT,
}

type ConfigType = { [V in keyof typeof Config]: string }

export default requireAll(
  !!process.env.GATSBY_BRANCH ? Config : StorybookStub
) as ConfigType
