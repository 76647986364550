import { ApolloClient } from "apollo-client"
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory"
import { HttpLink } from "apollo-link-http"
import fetchPonyfill from "fetch-ponyfill"
import config from "../config"

const { fetch } = fetchPonyfill()
export default (params?) => {
  const url = new URL(config.GATSBY_SHOPIFY_STOREFRONT_API_ENDPOINT)

  if (params) {
    Object.keys(params)
      .filter((k) => !!params[k])
      .forEach((k) => {
        url.searchParams.append(k, params[k])
      })
  }

  return new ApolloClient({
    cache: new InMemoryCache({
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData: {
          __schema: {
            types: [],
          },
        },
      }),
    }),
    link: new HttpLink({
      uri: url.href,
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token":
          config.GATSBY_SHOPIFY_STOREFRONT_API_KEY,
      },
      fetch,
    }),
  })
}
