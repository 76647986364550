export const UNKNOWN_LOCATION = "UNKNOWN_LOCATION"
const FBA_CA = "FBA_CA"
const FBA_UK = "FBA_UK"
const KERRY_LOGISTICS = "KERRY_LOGISTICS"
const MAINFREIGHT_LOGISTICS = "MAINFREIGHT_LOGISTICS"
const NINE_YARDS_LOGISTICS = "NINE_YARDS_LOGISTICS"
const EGO_CASES_VIA_SHOPIFY_COLLECTIVE = "EGO_CASES_VIA_SHOPIFY_COLLECTIVE"

const warehouseIdMap = {
  [FBA_CA]: "gid://shopify/Location/61837508696",
  [FBA_UK]: "gid://shopify/Location/61837803608",
  [KERRY_LOGISTICS]: "gid://shopify/Location/37217075288", //  International
  [MAINFREIGHT_LOGISTICS]: "gid://shopify/Location/10890703", // Domestic
  [NINE_YARDS_LOGISTICS]: "gid://shopify/Location/37217108056",
  [EGO_CASES_VIA_SHOPIFY_COLLECTIVE]: "gid://shopify/Location/63702532184",
}

export const DEFAULT_DOMESTIC_SHIPPING_MESSAGE =
  "Orders over $39 ship free; Free 2-Day above $80"
export const DEFAULT_INTERNATIONAL_SHIPPING_MESSAGE =
  "International orders over $39 USD ship free"
export const EGO_CASES_VIA_SHOPIFY_COLLECTIVE_MESSSAGE =
  "$5 Shipping on US orders"

export default {
  UNKNOWN_LOCATION: [
    {
      location: warehouseIdMap[MAINFREIGHT_LOGISTICS],
      shippingMessage: DEFAULT_DOMESTIC_SHIPPING_MESSAGE,
    },
    {
      location: warehouseIdMap[EGO_CASES_VIA_SHOPIFY_COLLECTIVE],
      shippingMessage: EGO_CASES_VIA_SHOPIFY_COLLECTIVE_MESSSAGE,
    },
  ],
  DEFAULT: [
    {
      location: warehouseIdMap[KERRY_LOGISTICS],
      shippingMessage: DEFAULT_INTERNATIONAL_SHIPPING_MESSAGE,
    },
  ],
  US: [
    {
      location: warehouseIdMap[MAINFREIGHT_LOGISTICS],
      shippingMessage: DEFAULT_DOMESTIC_SHIPPING_MESSAGE,
    },
    {
      location: warehouseIdMap[EGO_CASES_VIA_SHOPIFY_COLLECTIVE],
      shippingMessage: EGO_CASES_VIA_SHOPIFY_COLLECTIVE_MESSSAGE,
    },
  ],
  GB: [
    {
      location: warehouseIdMap[FBA_UK],
      shippingMessage: "UK orders over $39 USD ship free",
    },
    {
      location: warehouseIdMap[NINE_YARDS_LOGISTICS],
      shippingMessage: "UK orders over $39 USD ship free",
    },
    {
      location: warehouseIdMap[KERRY_LOGISTICS],
      shippingMessage: DEFAULT_INTERNATIONAL_SHIPPING_MESSAGE,
    },
    {
      location: warehouseIdMap[EGO_CASES_VIA_SHOPIFY_COLLECTIVE],
      shippingMessage: "Not available in UK",
    },
  ],
  CA: [
    {
      location: warehouseIdMap[FBA_CA],
      shippingMessage: "Canadian orders over $39 USD ship free",
    },
    {
      location: warehouseIdMap[EGO_CASES_VIA_SHOPIFY_COLLECTIVE],
      shippingMessage: "Not available in Canada",
    },
  ],
  MX: [
    {
      location: warehouseIdMap[KERRY_LOGISTICS],
      shippingMessage:
        "Pedidos mexicanos superiores a $39 USD se envían gratis",
    },
    {
      location: warehouseIdMap[EGO_CASES_VIA_SHOPIFY_COLLECTIVE],
      shippingMessage: "Not available in Mexico",
    },
  ],
  AE: [
    {
      location: warehouseIdMap[KERRY_LOGISTICS],
      shippingMessage: DEFAULT_INTERNATIONAL_SHIPPING_MESSAGE,
    },
  ],
  IL: [
    {
      location: warehouseIdMap[KERRY_LOGISTICS],
      shippingMessage: DEFAULT_INTERNATIONAL_SHIPPING_MESSAGE,
    },
  ],
  JP: [
    {
      location: warehouseIdMap[KERRY_LOGISTICS],
      shippingMessage: "Japan orders over $39 ship free",
    },
    {
      location: warehouseIdMap[EGO_CASES_VIA_SHOPIFY_COLLECTIVE],
      shippingMessage: "Not available in Japan",
    },
  ],
  HK: [
    {
      location: warehouseIdMap[KERRY_LOGISTICS],
      shippingMessage: "Hong Kong orders over $39 USD ship free",
    },
    {
      location: warehouseIdMap[EGO_CASES_VIA_SHOPIFY_COLLECTIVE],
      shippingMessage: "Not available in Hong Kong",
    },
  ],
  TH: [
    {
      location: warehouseIdMap[KERRY_LOGISTICS],
      shippingMessage: "Thailand orders over $39 USD ship free",
    },
    {
      location: warehouseIdMap[EGO_CASES_VIA_SHOPIFY_COLLECTIVE],
      shippingMessage: "Not available in Thailand",
    },
  ],
}
