import { Models } from "@rematch/core"
import customer from "./customer"
import checkout from "./checkout"
import discounts from "./discounts"
import search from "./search"

export interface RootModel extends Models<RootModel> {
  customer: typeof customer
  checkout: typeof checkout
  discounts: typeof discounts
  search: typeof search
}

export const models: RootModel = { customer, checkout, discounts, search }
