import { ApolloClient } from "apollo-client"
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory"
import { HttpLink } from "apollo-link-http"
import fetchPonyfill from "fetch-ponyfill"
import config from "../config"

const { fetch } = fetchPonyfill()
export default () =>
  new ApolloClient({
    cache: new InMemoryCache({
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData: {
          __schema: {
            types: [],
          },
        },
      }),
    }),
    link: new HttpLink({
      uri: config.GATSBY_CONTENTFUL_GRAPHQL_ENDPOINT,
      headers: {
        authorization: `Bearer ${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}`,
      },
      fetch,
    }),
  })
