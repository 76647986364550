import React from "react"
import { getInventoryByLocation } from "@utils/api"

export const INVENTORY_ITEM_TRACKED = "INVENTORY_ITEM_TRACKED"
const INVENTORY_POLICY_CONTINUE = "CONTINUE"
const INVENTORY_POLICY_DENY = "DENY"

export interface InventoryData {
  [sku: string]: {
    [locationId: string]: {
      quantity: number
      isAvailable: boolean
      [INVENTORY_ITEM_TRACKED]: boolean
    }
  }
}

export const formatInventoryData = (responseData): InventoryData => {
  const inventoryData = {}

  const cleanedData = responseData.filter((data) => !!data)

  if (cleanedData.length) {
    cleanedData.forEach(({ sku, tracked, inventoryPolicy, inventory }) => {
      const locationData = {}

      inventory.forEach(({ available, locationId }) => {
        locationData[locationId] = {
          quantity: available,
          isAvailable:
            available > 0 || inventoryPolicy === INVENTORY_POLICY_CONTINUE,
        }
      })

      const cleanedSku = sku.replace("__", "/")
      inventoryData[cleanedSku] = locationData
      inventoryData[cleanedSku][INVENTORY_ITEM_TRACKED] = tracked || false
    })
  }

  return inventoryData
}

export default (skus) => {
  const [inventoryData, setInventoryData] = React.useState<InventoryData>({})

  React.useEffect(() => {
    getInventoryByLocation(skus).then((data) => {
      if (data) {
        const formattedData = formatInventoryData(data)
        setInventoryData(formattedData)
      }
    })
  }, [skus])

  return {
    inventoryData,
  }
}
