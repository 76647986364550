import gql from "graphql-tag"
import { CheckoutFields, CustomerFields, OrderFields } from "./fragments"

export const GetCheckout = gql`
  ${CheckoutFields}
  query GetCheckout($checkoutId: ID!) {
    node(id: $checkoutId) {
      ...CheckoutFields
    }
  }
`

export const GetCustomer = gql`
  ${CustomerFields}
  query GetCustomer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      ...CustomerFields
    }
  }
`

export const GetOrderStatus = gql`
  ${OrderFields}
  query GetOrderStatus($query: String!) {
    orders(first: 1, query: $query) {
      edges {
        node {
          ...OrderFields
        }
      }
    }
  }
`
