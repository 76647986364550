import Axios from "axios"

export const getAutomaticDiscounts = () => {
  return Axios.post("/api/postAutomaticDiscounts", {})
    .then((response) => response.data)
    .catch((error) => {
      console.error(error)
    })
}

export const postBulkOrder = (data, callback) => {
  return Axios({
    method: "POST",
    url: "/api/postBulkOrder",
    data,
    headers: { "Content-Type": "application/json", Accept: "*/*" },
  })
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
    .finally(() => {
      callback()
    })
}

export const getCustomerZipCode = () => {
  return Axios.get(
    `https://ipapi.co/json?key=Jaio0rg93RS6UPxzEXB19vvw4NKUb292e9pLeiDOjsfnSjyLc5`
  )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error)
    })
}

export const getFenixShippingEstimate = (
  buyerZipCode: string,
  skus: Array<{ sku: string; quantity: number }>
) => {
  return Axios.post("/api/postFenixShippingEstimate", {
    buyerZipCode,
    skus,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error)
    })
}

export const getInventoryByLocation = (skus: Array<string>) => {
  return Axios.post("/api/postInventoryByLocation", {
    skus,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error)
    })
}

export const getOrder = (data) => {
  return Axios.post("/api/postOrder", data)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error)
    })
}

export const postOutOfStock = (data, callback) => {
  Axios({
    method: "POST",
    url: "/api/postOutOfStock",
    data,
    headers: { "Content-Type": "application/json", Accept: "*/*" },
  })
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
    .finally(() => {
      callback()
    })
}

export const postSubscribeNewsletter = (data, callback) => {
  return Axios({
    method: "POST",
    url: "/api/postSubscribeNewsletter",
    data,
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
    .finally(() => {
      callback()
    })
}

export const postWarrantyClaim = (data, callback) => {
  return Axios({
    method: "POST",
    url: "/api/postWarrantyClaim",
    data,
    headers: { "Content-Type": "application/json", Accept: "*/*" },
  })
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
    .finally(() => {
      callback()
    })
}

export const getYotpoCustomerData = (data) => {
  return Axios({
    method: "POST",
    url: "/api/getYotpoCustomer",
    data,
  })
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}
