export const formatCheckoutURL = (checkoutURL: string) => {
  return process.env.GATSBY_SHOPIFY_SHOP_DOMAIN &&
    process.env.GATSBY_SHOPIFY_SHOP_CUSTOM_DOMAIN
    ? checkoutURL.replace(
        process.env.GATSBY_SHOPIFY_SHOP_DOMAIN,
        process.env.GATSBY_SHOPIFY_SHOP_CUSTOM_DOMAIN
      )
    : checkoutURL
}

export const getProductURL = (productSlug: string, variantSku?: string) =>
  variantSku
    ? `/products/${productSlug}/?variant=${variantSku}`
    : `/products/${productSlug}`

export const getPrintedDesignUrl = (productSlug, sku) =>
  `/printed-designs/${productSlug}/${sku}`

export const getShopURL = () => `/collections/all/`

export const getShopURLForCollection = (collectionSlug) =>
  `/collections/${collectionSlug}/`

export const getShopURLForSeries = (seriesSlug) =>
  `/collections/${seriesSlug}-series/`

export const getShopURLForSeriesDeviceType = (seriesSlug, deviceType) =>
  `/collections/${seriesSlug}-series-${deviceType}/`

export const getShopURLForDevice = (deviceSlug) => `/collections/${deviceSlug}/`

export const getShopURLForDeviceType = (deviceTypeSlug) =>
  `/collections/${deviceTypeSlug}/`

export const getShopURLForBrandDeviceType = (brandSlug, deviceTypeSlug) =>
  `/collections/${brandSlug}-${deviceTypeSlug}/`

export const getExploreEntryTypeURL = (entryTypeSlug) => `/${entryTypeSlug}/`

export const getExploreEntryURL = (entryTypeSlug, entrySlug) =>
  `/${entryTypeSlug}/${entrySlug}/`
