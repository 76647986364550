import inventoryRules from "./inventoryRules"
import _ from "lodash-es"

const notAvailableResponse = {
  quantity: 0,
  isAvailable: false,
  shippingMessage: null,
}

const getAvailabilityByRegion = (
  region: string,
  sku: string,
  inventoryData: any
) => {
  const warehouses = inventoryRules[region] || inventoryRules.DEFAULT
  const inventoryDataForSku = inventoryData[sku]

  if (!inventoryDataForSku) {
    return notAvailableResponse
  }

  const warehouseWithAvailability = warehouses.find(({ location }) => {
    const warehouse = inventoryDataForSku[location]
    return warehouse && warehouse.isAvailable
  })

  if (!warehouseWithAvailability) {
    return notAvailableResponse
  }

  return {
    quantity: inventoryDataForSku[warehouseWithAvailability.location].quantity,
    isAvailable: true,
    shippingMessage: warehouseWithAvailability.shippingMessage,
  }
}

export default getAvailabilityByRegion
