// Checking available inventory for a product on refresh

import { createModel } from "@rematch/core"
import { RootModel } from "./index"
import DefaultClient from "apollo-boost"
import createShopifyClient from "@shopify/createApolloClient"
import { GetCheckout } from "@shopify/graphql/queries"
import {
  CreateCheckout,
  UpdateCheckout,
  ApplyDiscount,
  RemoveDiscount,
} from "@shopify/graphql/mutations"
import { UserError, LineItem, CheckoutData } from "@shopify/types"
import createContentfulClient from "@cms/createApolloClient"
import {
  GetCheckoutCartItemProductData,
  GetCheckoutCartItemPrintedDesignData,
} from "@cms/graphql/queries"
import {
  ToastProduct,
  CartProducts,
  CartItem,
  AddToCartPayload,
  LocalCheckout,
  BundledProduct,
} from "../types"
import {
  graphQLRequest,
  getMissingCartProductIds,
  formatContentfulCartProductsData,
  getLocalCheckoutProps,
  buildLocalCheckout,
  createCheckoutLineItem,
  getLineItemFromCheckoutData,
  partitionLineItemsByVariantId,
} from "../utils"
import { getIn, isNilOrEmpty } from "@utils/index"
import { formatCheckoutURL } from "@utils/urls"
import { getInventoryByLocation } from "@utils/api"
import getAvailabilityByRegion from "@utils/getAvailabilityByRegion"
import { formatInventoryData } from "@hooks/useMultiWarehouseInventory"
import { getLocalStorage, writeLocalStorage } from "@utils/storage"
import {
  UTM_PARAMS_KEY,
  VISITOR_INFO_KEY,
  ELEVAR_COOKIES_KEY,
  CHECKOUT_KEY,
  DISCOUNT_KEY,
  PASSED_DISCOUNT_KEY,
  LAST_PASSED_DISCOUNT_APPLIED_KEY,
} from "@constants/storageKeys"
import * as R from "ramda"

const CHECKOUT_NOTE = "Checkout created via UAG v2"
export const AUTOMATIC_DISCOUNT_APPLICATION = "AutomaticDiscountApplication"
export const DISCOUNT_CODE_APPLICATION = "DiscountCodeApplication"
export const MANUAL_CODE_APPLICATION = "ManualDiscountApplication"
export const SCRIPT_DISCOUNT_APPLICATION = "ScriptDiscountApplication"

export interface CheckoutState {
  loadingCheckoutData: boolean
  checkoutDataFetched: boolean
  checkoutVerified: boolean
  checkoutId: string | null
  data: CheckoutData
  localCheckout: LocalCheckout
  cartProducts: CartProducts
  toastProduct: ToastProduct | null
  showPromoToast: boolean
  showErrorMessage: boolean
  reconciliationCount: number
  activeBundle: BundledProduct | null
  activeBundleInventory: number
  activeBundleReady: boolean
  bundledProductCount: number
}

const checkout = createModel<RootModel>()({
  name: "checkout",
  state: {
    loadingCheckoutData: false,
    checkoutDataFetched: false,
    checkoutVerified: false,
    checkoutId: getLocalStorage(CHECKOUT_KEY),
    data: {} as CheckoutData,
    localCheckout: {} as LocalCheckout,
    cartProducts: {} as CartProducts,
    toastProduct: null,
    showPromoToast: false,
    showErrorMessage: false, // Something went wrong. Your cart has been adjusted.
    reconciliationCount: 0,
    activeBundle: null,
    activeBundleInventory: 0,
    activeBundleReady: false,
    bundledProductCount: 0,
  } as CheckoutState,
  // https://github.com/rematch/rematch/issues/816
  // @ts-ignore
  selectors: (slice) => ({
    quantity() {
      return slice((checkout) => {
        if (!checkout?.localCheckout?.items) {
          return 0
        }

        return checkout.localCheckout.items.reduce((total, item) => {
          return total + item.quantity
        }, 0)
      })
    },

    cartTotal() {
      return slice((checkout) => {
        return checkout.data?.subtotalPriceV2?.amount || "0.00"
      })
    },

    checkoutLink() {
      return slice((checkout) => {
        const checkoutLink = getIn(checkout, "data.webUrl")
        return checkoutLink ? formatCheckoutURL(checkoutLink) : undefined
      })
    },

    hasBundledProduct() {
      return slice((checkout) => {
        if (checkout.activeBundle && checkout.bundledProductCount > 0) {
          return true
        }

        return false
      })
    },

    couponCodeApplied() {
      return slice((checkout) => {
        const lineItems = getIn(
          checkout,
          "data.lineItems.edges",
          []
        ) as Array<any>
        let discountCode
        lineItems.forEach(({ node }) => {
          const discountAllocations = getIn(node, "discountAllocations", [])
          const discountApplication = discountAllocations.find(
            (discountAllocation) =>
              discountAllocation?.discountApplication?.__typename ===
              DISCOUNT_CODE_APPLICATION
          )

          if (discountApplication) {
            discountCode = discountApplication.discountApplication.code
            return true
          }
        })
        return discountCode
      })
    },

    automaticDiscountApplied() {
      return slice((checkout) => {
        const discountType = getIn(
          checkout,
          "data.discountApplications.edges.0.node.__typename"
        )
        return discountType === AUTOMATIC_DISCOUNT_APPLICATION
      })
    },

    automaticDiscountAllocations() {
      return slice((checkout) => {
        const lineItems = getIn(checkout, "data.lineItems.edges", []) as Array<{
          node: LineItem
        }>

        return lineItems.reduce((discountAllocations, { node }) => {
          if (node.discountAllocations?.length) {
            const discount = node.discountAllocations[0]
            const sku = node.variant.sku
            discountAllocations[sku] = discount.allocatedAmount.amount
          }

          return discountAllocations
        }, {})
      })
    },

    scriptDiscountApplied() {
      return slice((checkout) => {
        const discountType = getIn(
          checkout,
          "data.discountApplications.edges.0.node.__typename"
        )
        return discountType === SCRIPT_DISCOUNT_APPLICATION
      })
    },

    scriptDiscountAllocations() {
      return slice((checkout) => {
        const lineItems = getIn(checkout, "data.lineItems.edges", []) as Array<{
          node: LineItem
        }>

        return lineItems.reduce((discountAllocations, { node }) => {
          if (node.discountAllocations?.length) {
            const discount = node.discountAllocations[0]
            const sku = node.variant.sku
            discountAllocations[sku] = {
              amount: discount.allocatedAmount.amount,
              title: discount.discountApplication.title,
            }
          }

          return discountAllocations
        }, {})
      })
    },
  }),
  reducers: {
    setLoadingCheckoutData(state: CheckoutState, loadingCheckoutData: boolean) {
      return {
        ...state,
        loadingCheckoutData,
      }
    },

    setCheckoutDataFetched(state: CheckoutState, checkoutDataFetched: boolean) {
      return {
        ...state,
        checkoutDataFetched,
      }
    },

    setCheckoutVerified(state: CheckoutState, checkoutVerified: boolean) {
      return {
        ...state,
        checkoutVerified,
      }
    },

    clearCheckout(state: CheckoutState) {
      return {
        ...state,
        checkoutId: null,
        data: {} as CheckoutData,
        localCheckout: {} as LocalCheckout,
      }
    },

    updateCheckout(state: CheckoutState, data: CheckoutData) {
      return {
        ...state,
        checkoutId: data.id,
        data,
      }
    },

    updateLocalCheckout(state: CheckoutState, localCheckout: LocalCheckout) {
      return {
        ...state,
        localCheckout,
      }
    },

    updateLocalCheckoutItems(state: CheckoutState, items: Array<CartItem>) {
      const localCheckoutItems = items.filter(
        ({ sku }) => !state.activeBundle || state.activeBundle?.sku !== sku
      )
      return {
        ...state,
        localCheckout: {
          ...state.localCheckout,
          items: localCheckoutItems,
        },
      }
    },

    updateLocalCheckoutProps(state: CheckoutState, localCheckoutProps) {
      return {
        ...state,
        localCheckout: {
          ...localCheckoutProps,
          items: state.localCheckout.items,
        },
      }
    },

    updateCartProducts(state: CheckoutState, data: CartProducts) {
      return {
        ...state,
        cartProducts: {
          ...state.cartProducts,
          ...data,
        },
      }
    },

    updateToastProduct(
      state: CheckoutState,
      toastProduct: ToastProduct | null
    ) {
      return {
        ...state,
        toastProduct: toastProduct,
      }
    },

    updateShowPromoToast(state: CheckoutState, showPromoToast: boolean) {
      return {
        ...state,
        showPromoToast,
      }
    },

    // -> Cart HOC
    setShowErrorMessage(state: CheckoutState, showErrorMessage: boolean) {
      return {
        ...state,
        showErrorMessage,
      }
    },

    setReconciliationCount(state: CheckoutState, reconciliationCount: number) {
      return {
        ...state,
        reconciliationCount,
      }
    },

    setActiveBundle(state: CheckoutState, activeBundle: BundledProduct) {
      return {
        ...state,
        activeBundle,
      }
    },

    setActiveBundleInventory(
      state: CheckoutState,
      activeBundleInventory: number
    ) {
      return {
        ...state,
        activeBundleInventory,
      }
    },

    setActiveBundleReady(state: CheckoutState, activeBundleReady: boolean) {
      return {
        ...state,
        activeBundleReady,
      }
    },

    setBundledProductCount(state: CheckoutState, bundledProductCount: number) {
      return {
        ...state,
        bundledProductCount,
      }
    },
  },
  effects: (dispatch: any) => ({
    setCheckoutId(checkoutId) {
      writeLocalStorage(CHECKOUT_KEY, checkoutId)
    },

    clearCheckout() {
      writeLocalStorage(CHECKOUT_KEY, null)
      writeLocalStorage(DISCOUNT_KEY, null)
    },

    async removeInvalidLineItems(
      {
        lineItems,
        cartProducts,
      }: {
        lineItems: Array<{ node: LineItem }>
        cartProducts: CartProducts
      },
      rootState: any
    ) {
      const cleanedLineItems = lineItems
        .filter(
          ({ node: lineItem }) =>
            !!cartProducts[lineItem.variant.sku] ||
            (rootState.checkout.activeBundle &&
              lineItem.variant.sku === rootState.checkout.activeBundle.sku)
        )
        .map(({ node: lineItem }) => ({
          variantId: lineItem.variant.id,
          quantity: 0,
        }))

      dispatch.checkout.setLoadingCheckoutData(true)

      const client: DefaultClient<any> = createShopifyClient()
      const { data, errors } = await graphQLRequest(client.mutate, {
        mutation: UpdateCheckout,
        variables: {
          checkoutId: rootState.checkout.checkoutId,
          lineItems: cleanedLineItems,
        },
      })

      dispatch.checkout.setLoadingCheckoutData(false)

      const checkoutErrors: Array<UserError> = getIn(
        data,
        "checkoutLineItemsReplace.userErrors",
        []
      )

      if (errors || (checkoutErrors && checkoutErrors.length)) {
        dispatch.checkout.reconcileCheckout()
        return
      }

      const checkout: CheckoutData = getIn(
        data,
        "checkoutLineItemsReplace.checkout"
      )

      dispatch.checkout.updateCheckout(checkout)
      dispatch.checkout.updateLocalCheckoutProps(
        getLocalCheckoutProps(checkout)
      )
    },

    async fetchContentfulCartProducts(ids) {
      const [contentfulProductVariantIds, contentfulPrintedDesignVariantIds] =
        ids
      const client: DefaultClient<any> = createContentfulClient()

      let productVariantData = {}
      let printedDesignVariantData = {}

      if (contentfulProductVariantIds.length > 0) {
        const { data, errors } = await graphQLRequest(client.query, {
          query: GetCheckoutCartItemProductData,
          variables: { ids: contentfulProductVariantIds },
        })

        if (!errors) {
          productVariantData = data
        }
      }

      if (contentfulPrintedDesignVariantIds.length > 0) {
        const { data, errors } = await graphQLRequest(client.query, {
          query: GetCheckoutCartItemPrintedDesignData,
          variables: { ids: contentfulPrintedDesignVariantIds },
        })

        if (!errors) {
          printedDesignVariantData = data
        }
      }

      const formattedData = formatContentfulCartProductsData({
        ...productVariantData,
        ...printedDesignVariantData,
      })
      return formattedData
    },

    async getLocalCheckout({
      checkout,
      cartProducts,
      bundledProductSku,
    }: {
      checkout: CheckoutData
      cartProducts: CartProducts
      bundledProductSku: string | undefined
    }) {
      const lineItems = getIn(checkout, "lineItems.edges", []) as Array<{
        node: LineItem
      }>

      if (!lineItems.length) {
        dispatch.checkout.updateLocalCheckout({} as LocalCheckout)
        return
      }

      const [missingCartProductIds, missingCartPrintedDesignIds] =
        getMissingCartProductIds(checkout, cartProducts)
      let updatedCartProducts = cartProducts

      if (missingCartProductIds.length || missingCartPrintedDesignIds.length) {
        const formattedData =
          await dispatch.checkout.fetchContentfulCartProducts([
            missingCartProductIds,
            missingCartPrintedDesignIds,
          ])

        updatedCartProducts = {
          ...cartProducts,
          ...formattedData,
        }

        dispatch.checkout.updateCartProducts(updatedCartProducts)

        if (
          missingCartProductIds.length + missingCartPrintedDesignIds.length !==
          Object.keys(formattedData).length
        ) {
          dispatch.checkout.removeInvalidLineItems({
            lineItems,
            cartProducts: updatedCartProducts,
          })
        }
      }

      return buildLocalCheckout(
        checkout,
        updatedCartProducts,
        bundledProductSku
      )
    },

    // Build localCheckout from latest Checkout data
    async reconcileCheckout(_, rootState) {
      if (rootState.checkout.reconciliationCount > 5) {
        dispatch.checkout.clearCheckout()
        dispatch.checkout.setReconciliationCount(0)
      } else {
        dispatch.checkout.setReconciliationCount(
          rootState.checkout.reconciliationCount + 1
        )
      }

      const localCheckout = await dispatch.checkout.getLocalCheckout({
        checkout: rootState.checkout.data,
        cartProducts: rootState.checkout.cartProducts,
        bundledProductSku: rootState.checkout.activeBundle?.sku,
      })

      dispatch.checkout.updateLocalCheckout(localCheckout)
    },

    async fetchCheckout(checkoutId, rootState) {
      dispatch.checkout.setLoadingCheckoutData(true)

      const client: DefaultClient<any> = createShopifyClient()
      const { data, errors } = await graphQLRequest(client.query, {
        query: GetCheckout,
        variables: {
          checkoutId,
        },
      })

      dispatch.checkout.setLoadingCheckoutData(false)

      if (errors) {
        dispatch.checkout.clearCheckout()
        dispatch.checkout.setCheckoutDataFetched(true)
        return
      }

      let checkout: CheckoutData = getIn(data, "node", undefined)
      const lineItems = getIn(checkout, "lineItems.edges", []) as Array<{
        node: LineItem
      }>

      const invalidLineItem =
        !R.isEmpty(lineItems) &&
        R.find((item: { node: LineItem }) => isNilOrEmpty(item.node.variant))(
          checkout.lineItems.edges
        )

      if (checkout && !checkout.completedAt && !invalidLineItem) {
        const skus = lineItems.map(({ node: { variant } }) => {
          return variant.sku
        }) as Array<string>

        if (!rootState.checkout.checkoutVerified && skus.length > 0) {
          const inventoryData = await getInventoryByLocation(skus)
          const formattedInventoryData = inventoryData
            ? formatInventoryData(inventoryData)
            : {}
          const customerLocation = rootState.customer.customerLocationFetched
            ? rootState.customer.customerLocation
            : await dispatch.customer.fetchCustomerLocation()

          const availableLineItems = lineItems.filter((item) => {
            const availabilityData = getAvailabilityByRegion(
              customerLocation.customerCountryCode,
              item.node.variant.sku,
              formattedInventoryData
            )

            const availableQuantity = availabilityData?.quantity || 0
            return availableQuantity
          })

          dispatch.checkout.setCheckoutVerified(true)

          if (lineItems.length !== availableLineItems.length) {
            dispatch.checkout.clearCheckout()
            dispatch.checkout.setCheckoutDataFetched(true)
            return
          }
        }

        dispatch.checkout.setCheckoutId(checkout.id)
        dispatch.checkout.updateCheckout(checkout)

        const passedDiscount = getLocalStorage(PASSED_DISCOUNT_KEY)
        const lastPassedDiscount = getLocalStorage(
          LAST_PASSED_DISCOUNT_APPLIED_KEY
        )

        if (passedDiscount && passedDiscount !== lastPassedDiscount) {
          await dispatch.checkout.updateDiscount(passedDiscount, rootState)
          writeLocalStorage(LAST_PASSED_DISCOUNT_APPLIED_KEY, passedDiscount)
        }

        if (rootState.checkout.activeBundle) {
          if (skus.indexOf(rootState.checkout.activeBundle.sku) !== -1) {
            const activeBundleLineItem = R.find(
              ({ node: { variant } }) =>
                !!rootState.checkout.activeBundle &&
                rootState.checkout.activeBundle.sku === variant.sku,
              lineItems
            ) as any

            if (activeBundleLineItem) {
              dispatch.checkout.setBundledProductCount(
                activeBundleLineItem.node.quantity as number
              )
            }
          } else {
            const bundledWithProduct = R.find(
              ({ node: { variant } }) =>
                !!rootState.checkout.activeBundle &&
                rootState.checkout.activeBundle.bundledWith.indexOf(
                  variant.sku
                ) !== -1,
              lineItems
            ) as any

            if (bundledWithProduct) {
              dispatch.checkout.clearCheckout()
              dispatch.checkout.setCheckoutDataFetched(true)
              return
            }
          }
        }

        const localCheckout = await dispatch.checkout.getLocalCheckout({
          checkout,
          cartProducts: rootState.checkout.cartProducts,
          bundledProductSku: rootState.checkout.activeBundle?.sku,
        })

        dispatch.checkout.updateLocalCheckout(localCheckout)
      } else {
        dispatch.checkout.clearCheckout()
      }
      dispatch.checkout.setCheckoutDataFetched(true)
    },

    async createCheckout(cartItem: CartItem, rootState) {
      const checkoutItems = [createCheckoutLineItem(cartItem)]
      let hasBundledProduct

      if (
        rootState.checkout.activeBundle &&
        rootState.checkout.activeBundle.bundledWith?.indexOf(cartItem.sku) !==
          -1 &&
        rootState.checkout.activeBundleInventory > 0
      ) {
        hasBundledProduct = true
        checkoutItems.push({
          customAttributes: [
            { key: "_contentfulProductId", value: "bundledProduct" },
            { key: "_contentfulVariantId", value: "bundledProduct" },
            { key: "_productSlug", value: "bundledProduct" },
          ],
          variantId: btoa(
            `gid://shopify/ProductVariant/${rootState.checkout.activeBundle.variantId}`
          ),
          quantity: cartItem.quantity,
        } as any)
      }
      dispatch.checkout.updateLocalCheckoutItems([cartItem])
      dispatch.checkout.updateCartProducts({
        [cartItem.sku]: cartItem,
      })

      dispatch.checkout.setLoadingCheckoutData(true)

      const utmParams = getLocalStorage(UTM_PARAMS_KEY)
      const visitorInfo = getLocalStorage(VISITOR_INFO_KEY)
      const elevarCookies = getLocalStorage(ELEVAR_COOKIES_KEY)
      const passedDiscount = getLocalStorage(PASSED_DISCOUNT_KEY)
      const lastPassedDiscount = getLocalStorage(
        LAST_PASSED_DISCOUNT_APPLIED_KEY
      )
      const noteAttributes = [] as any

      const validElevarCookies = Object.keys(elevarCookies || {}).filter(
        (k) => !!elevarCookies[k]
      )
      validElevarCookies.forEach((k) => {
        noteAttributes.push({
          name: k,
          value: elevarCookies[k],
        })
      })

      const validVisitorInfo = Object.keys(visitorInfo || {}).filter(
        (k) => !!visitorInfo[k]
      )
      if (validVisitorInfo.length) {
        const cleanedVisitorInfo = validVisitorInfo.reduce((obj, k) => {
          obj[k] = visitorInfo[k]
          return obj
        }, {})

        noteAttributes.push({
          name: "_elevar_visitor_info",
          value: JSON.stringify(cleanedVisitorInfo),
        })
      }

      const checkoutVariables: any = {
        lineItems: checkoutItems,
        note: CHECKOUT_NOTE,
      }

      if (noteAttributes.length) {
        checkoutVariables.customAttributes = {
          note_attributes: noteAttributes,
        }
      }

      const _window = typeof window !== "undefined" && (window as any)

      if (_window && getIn(_window, "glopal.config.target.countryCode")) {
        checkoutVariables.buyerIdentity = {
          countryCode: getIn(_window, "glopal.config.target.countryCode"),
        }
      }

      // const client: DefaultClient<any> = createShopifyClient(utmParams)
      const client: DefaultClient<any> = createShopifyClient()
      const { data, errors } = await graphQLRequest(client.mutate, {
        mutation: CreateCheckout,
        variables: checkoutVariables,
      })

      dispatch.checkout.setLoadingCheckoutData(false)

      const checkoutErrors: Array<UserError> = getIn(
        data,
        "checkoutCreate.checkoutUserErrors",
        []
      )

      if (errors || (checkoutErrors && checkoutErrors.length)) {
        writeLocalStorage(UTM_PARAMS_KEY, {})
        dispatch.checkout.setShowErrorMessage(true)
        dispatch.checkout.updateLocalCheckout({} as LocalCheckout)
        return
      }

      const checkout: CheckoutData = getIn(data, "checkoutCreate.checkout")
      dispatch.customer.associateCheckoutId(checkout.id)
      dispatch.checkout.setCheckoutId(checkout.id)
      dispatch.checkout.updateCheckout(checkout)
      dispatch.checkout.updateLocalCheckoutProps(
        getLocalCheckoutProps(checkout)
      )

      if (passedDiscount && passedDiscount !== lastPassedDiscount) {
        await dispatch.checkout.updateDiscount(passedDiscount, rootState)
        writeLocalStorage(LAST_PASSED_DISCOUNT_APPLIED_KEY, passedDiscount)
      }

      if (hasBundledProduct) {
        dispatch.checkout.setBundledProductCount(cartItem.quantity)
      }
    },

    // Updates localCheckout props and reconciles if needed
    async updateCheckoutItem(
      {
        checkoutId,
        cartItem,
      }: {
        checkoutId: string
        cartItem: CartItem
      },
      rootState
    ) {
      const { shopifyVariantId, quantity } = cartItem
      const [changedLineItem, unchangedLineItems] =
        partitionLineItemsByVariantId(
          getIn(rootState, "checkout.data.lineItems.edges", []),
          btoa(shopifyVariantId)
        )
      const [bundledProduct, filteredUnchangedLineItems] = R.partition(
        ({ variantId }: { variantId: string }) => {
          if (rootState.checkout.activeBundle) {
            let decodedVariantId
            try {
              decodedVariantId = atob(variantId)
            } catch (error) {
              decodedVariantId = variantId
            }

            const cleanedVariantId = decodedVariantId.replace(
              "gid://shopify/ProductVariant/",
              ""
            )

            if (
              cleanedVariantId === rootState.checkout.activeBundle.variantId
            ) {
              return true
            }
          }

          return false
        },
        unchangedLineItems
      ) as any
      const bundledProductLineItem = bundledProduct[0]
      let bundledProductCount

      let lineItems

      if (
        rootState.checkout.activeBundle &&
        rootState.checkout.activeBundle.bundledWith.indexOf(cartItem.sku) !== -1
      ) {
        // update quantity
        if (changedLineItem.length > 0) {
          const delta =
            cartItem.quantity === 0
              ? -changedLineItem[0].quantity
              : cartItem.quantity - changedLineItem[0].quantity
          const deltaAdjustedProductCount =
            rootState.checkout.bundledProductCount + delta

          if (
            rootState.checkout.activeBundleInventory > deltaAdjustedProductCount
          ) {
            if (quantity === 0) {
              const updatedBundledProductLineItem = {
                ...bundledProductLineItem,
                quantity: bundledProductLineItem.quantity + delta,
              }

              lineItems = [...filteredUnchangedLineItems]

              if (updatedBundledProductLineItem.quantity > 0) {
                lineItems.push(updatedBundledProductLineItem)
                bundledProductCount = updatedBundledProductLineItem.quantity
              } else {
                bundledProductCount = 0
              }
            } else {
              lineItems = [
                ...filteredUnchangedLineItems,
                createCheckoutLineItem(cartItem),
                {
                  ...bundledProductLineItem,
                  quantity: bundledProductLineItem.quantity + delta,
                },
              ]

              bundledProductCount = bundledProductLineItem.quantity + delta
            }
          }
        } else {
          // add to cart
          if (bundledProductLineItem) {
            // cart already contains bundled product
            lineItems = [
              ...filteredUnchangedLineItems,
              createCheckoutLineItem(cartItem),
              {
                ...bundledProductLineItem,
                quantity: bundledProductLineItem.quantity + quantity,
              },
            ]

            bundledProductCount = bundledProductLineItem.quantity + quantity
          } else {
            // add bundled product to cart
            lineItems = [
              ...unchangedLineItems,
              createCheckoutLineItem(cartItem),
              {
                customAttributes: [
                  { key: "_contentfulProductId", value: "bundledProduct" },
                  { key: "_contentfulVariantId", value: "bundledProduct" },
                  { key: "_productSlug", value: "bundledProduct" },
                ],
                variantId: btoa(
                  `gid://shopify/ProductVariant/${rootState.checkout.activeBundle.variantId}`
                ),
                quantity: cartItem.quantity,
              },
            ]

            bundledProductCount = cartItem.quantity
          }
        }
      } else {
        lineItems =
          quantity === 0
            ? unchangedLineItems
            : [...unchangedLineItems, createCheckoutLineItem(cartItem)]
      }

      dispatch.checkout.setLoadingCheckoutData(true)

      const client: DefaultClient<any> = createShopifyClient()
      const { data, errors } = await graphQLRequest(client.mutate, {
        mutation: UpdateCheckout,
        variables: {
          checkoutId,
          lineItems,
        },
      })

      dispatch.checkout.setLoadingCheckoutData(false)

      const checkoutErrors: Array<UserError> = getIn(
        data,
        "checkoutLineItemsReplace.userErrors",
        []
      )

      if (errors || (checkoutErrors && checkoutErrors.length)) {
        dispatch.checkout.reconcileCheckout()
        return
      }

      const checkout: CheckoutData = getIn(
        data,
        "checkoutLineItemsReplace.checkout"
      )

      dispatch.checkout.updateCheckout(checkout)
      dispatch.checkout.updateLocalCheckoutProps(
        getLocalCheckoutProps(checkout)
      )

      if (bundledProductCount || bundledProductCount === 0) {
        dispatch.checkout.setBundledProductCount(bundledProductCount)
      }
    },

    // On success, updates localCheckout props
    async updateDiscount(discountCode: string | undefined, rootState) {
      const client: DefaultClient<any> = createShopifyClient()
      const checkoutId = rootState.checkout.checkoutId

      if (discountCode) {
        dispatch.checkout.setLoadingCheckoutData(true)

        const { data, errors } = await graphQLRequest(client.mutate, {
          mutation: ApplyDiscount,
          variables: {
            checkoutId,
            discountCode,
          },
        })

        dispatch.checkout.setLoadingCheckoutData(false)

        const checkoutErrors: Array<UserError> = getIn(
          data,
          "checkoutDiscountCodeApplyV2.checkoutUserErrors",
          []
        )

        if (errors || (checkoutErrors && checkoutErrors.length)) {
          return getIn(checkoutErrors, "0", checkoutErrors)
        }

        const checkout: CheckoutData = getIn(
          data,
          "checkoutDiscountCodeApplyV2.checkout"
        )

        dispatch.checkout.updateCheckout(checkout)
        dispatch.checkout.updateLocalCheckoutProps(
          getLocalCheckoutProps(checkout)
        )

        return {}
      } else {
        dispatch.checkout.setLoadingCheckoutData(true)

        const { data, errors } = await graphQLRequest(client.mutate, {
          mutation: RemoveDiscount,
          variables: {
            checkoutId,
          },
        })

        dispatch.checkout.setLoadingCheckoutData(false)

        const checkoutErrors: Array<UserError> = getIn(
          data,
          "checkoutDiscountCodeRemove.checkoutUserErrors",
          []
        )

        if (errors || (checkoutErrors && checkoutErrors.length)) {
          console.log(errors, checkoutErrors)
          return
        }

        const checkout: CheckoutData = getIn(
          data,
          "checkoutDiscountCodeRemove.checkout"
        )

        dispatch.checkout.updateCheckout(checkout)
        dispatch.checkout.updateLocalCheckoutProps(
          getLocalCheckoutProps(checkout)
        )
      }
    },

    // -> Product Details
    addToCart({ cartProduct, showPromoToast }: AddToCartPayload, rootState) {
      if (rootState.checkout.checkoutId) {
        const lineItems = getIn(rootState, "checkout.data.lineItems.edges", [])
        const lineItem = getLineItemFromCheckoutData(
          lineItems,
          cartProduct.shopifyVariantId
        )
        const quantity = (lineItem && getIn(lineItem, "node.quantity")) || 0

        const updatedLocalCheckoutItems = lineItem
          ? rootState.checkout.localCheckout?.items?.map((item) => {
              if (item.shopifyVariantId === cartProduct.shopifyVariantId) {
                return { ...item, quantity: item.quantity + 1 }
              }
              return item
            }) || []
          : [
              ...(rootState.checkout.localCheckout?.items || []),
              { ...cartProduct, quantity: 1 },
            ]

        dispatch.checkout.updateLocalCheckoutItems(updatedLocalCheckoutItems)
        dispatch.checkout.updateCheckoutItem({
          checkoutId: rootState.checkout.checkoutId,
          cartItem: {
            ...cartProduct,
            quantity: quantity + 1,
          },
        })
      } else {
        dispatch.checkout.createCheckout({ ...cartProduct, quantity: 1 })
      }

      dispatch.checkout.updateCartProducts({
        ...rootState.checkout.cartProducts,
        [cartProduct.sku]: cartProduct,
      })

      if (showPromoToast) {
        dispatch.checkout.updateShowPromoToast(true)
        dispatch.checkout.clearShowPromoToast()
      } else {
        dispatch.checkout.updateToastProduct(
          R.pick(
            [
              "customPrint",
              "productSlug",
              "productName",
              "variantName",
              "variantDevice",
              "device",
              "image",
            ],
            cartProduct
          ) as ToastProduct
        )

        dispatch.checkout.clearToastProduct()
      }
    },

    // -> Cart HOC
    updateCart(cartItem: CartItem, rootState) {
      const updatedLocalCheckoutItems = R.pipe(
        R.map((item: CartItem) => {
          if (
            item.shopifyVariantId === cartItem.shopifyVariantId &&
            item.contentfulVariantId === cartItem.contentfulVariantId
          ) {
            return cartItem.quantity === 0 ? undefined : cartItem
          }
          return item
        }),
        R.filter((item: CartItem | undefined) => !!item)
      )(rootState.checkout.localCheckout.items) as Array<CartItem>

      dispatch.checkout.updateLocalCheckoutItems(updatedLocalCheckoutItems)
      dispatch.checkout.updateCheckoutItem({
        checkoutId: rootState.checkout.checkoutId,
        cartItem,
      })
    },

    async clearToastProduct() {
      await new Promise((resolve) => {
        setTimeout(resolve, 5000)
      })

      dispatch.checkout.updateToastProduct(null)
    },

    async clearShowPromoToast() {
      await new Promise((resolve) => {
        setTimeout(resolve, 5000)
      })

      dispatch.checkout.updateShowPromoToast(false)
    },

    // -> Cart HOC
    async clearShowErrorMessage() {
      await new Promise((resolve) => {
        setTimeout(resolve, 5000)
      })

      dispatch.checkout.setShowErrorMessage(false)
    },

    async fetchActiveBundleInventoryData(
      bundledProduct: BundledProduct,
      rootState
    ) {
      const inventoryData = await getInventoryByLocation([bundledProduct.sku])
      const [formattedInventoryData] = R.values(
        inventoryData ? formatInventoryData(inventoryData) : {}
      )
      const customerLocation = rootState.customer.customerLocation as any
      const availabilityData = getAvailabilityByRegion(
        customerLocation.customerCountryCode,
        bundledProduct.sku,
        formattedInventoryData
      )

      return availabilityData?.quantity || 0
    },

    async initActiveBundle(bundledProduct: BundledProduct) {
      dispatch.checkout.setActiveBundle(bundledProduct)

      const activeBundleInventory =
        await dispatch.checkout.fetchActiveBundleInventoryData(bundledProduct)

      dispatch.checkout.setActiveBundleInventory(activeBundleInventory)
      dispatch.checkout.setActiveBundleReady(true)
    },
  }),
})

export default checkout
