import { createModel } from "@rematch/core"
import { RootModel } from "./index"

export interface SearchState {
  searchValue: string
  lastSearchResult: string
}

const search = createModel<RootModel>()({
  name: "search",
  state: {
    searchValue: "",
    lastSearchResult: "",
  } as SearchState,
  // https://github.com/rematch/rematch/issues/816
  // @ts-ignore
  selectors: (slice) => ({}),
  reducers: {
    setSearchValue(state: SearchState, searchValue: string) {
      return {
        ...state,
        searchValue,
      }
    },
    setLastSearchResult(state: SearchState, lastSearchResult: string) {
      return {
        ...state,
        lastSearchResult,
      }
    },
  },
  effects: (dispatch: any) => ({}),
})

export default search
