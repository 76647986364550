import gql from "graphql-tag"
import {
  CheckoutUserErrorsFields,
  CheckoutFields,
  CustomerUserErrorsFields,
  CustomerAccessTokenFields,
  CustomerFields,
} from "./fragments"

export const CreateCheckout = gql`
  ${CheckoutFields}
  ${CheckoutUserErrorsFields}
  mutation CreateCheckout(
    $lineItems: [CheckoutLineItemInput!]
    $note: String!
    $buyerIdentity: CheckoutBuyerIdentityInput
  ) {
    checkoutCreate(
      input: {
        lineItems: $lineItems
        note: $note
        buyerIdentity: $buyerIdentity
      }
    ) {
      checkout {
        ...CheckoutFields
      }
      checkoutUserErrors {
        ...CheckoutUserErrorsFields
      }
    }
  }
`

export const UpdateCheckout = gql`
  ${CheckoutFields}
  ${CheckoutUserErrorsFields}
  mutation UpdateCheckout(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemInput!]!
  ) {
    checkoutLineItemsReplace(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        ...CheckoutFields
      }
      userErrors {
        ...CheckoutUserErrorsFields
      }
    }
  }
`

export const ApplyDiscount = gql`
  ${CheckoutFields}
  ${CheckoutUserErrorsFields}
  mutation ApplyDiscount($checkoutId: ID!, $discountCode: String!) {
    checkoutDiscountCodeApplyV2(
      checkoutId: $checkoutId
      discountCode: $discountCode
    ) {
      checkout {
        ...CheckoutFields
      }
      checkoutUserErrors {
        ...CheckoutUserErrorsFields
      }
    }
  }
`

export const RemoveDiscount = gql`
  ${CheckoutFields}
  ${CheckoutUserErrorsFields}
  mutation RemoveDiscount($checkoutId: ID!) {
    checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
      checkout {
        ...CheckoutFields
      }
      checkoutUserErrors {
        ...CheckoutUserErrorsFields
      }
    }
  }
`

export const CreateAccount = gql`
  ${CustomerFields}
  ${CustomerUserErrorsFields}
  mutation CreateAccount($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        ...CustomerFields
      }
      customerUserErrors {
        ...CustomerUserErrorsFields
      }
    }
  }
`

export const RecoverAccount = gql`
  ${CustomerUserErrorsFields}
  mutation RecoverAccount($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        ...CustomerUserErrorsFields
      }
    }
  }
`

export const ResetAccount = gql`
  ${CustomerFields}
  ${CustomerAccessTokenFields}
  ${CustomerUserErrorsFields}
  mutation ResetAccount($id: ID!, $input: CustomerResetInput!) {
    customerReset(id: $id, input: $input) {
      customer {
        ...CustomerFields
      }
      customerAccessToken {
        ...CustomerAccessTokenFields
      }
      customerUserErrors {
        ...CustomerUserErrorsFields
      }
    }
  }
`

export const ResetAccountByUrl = gql`
  ${CustomerFields}
  ${CustomerAccessTokenFields}
  ${CustomerUserErrorsFields}
  mutation ResetAccountByUrl($password: String!, $resetUrl: URL!) {
    customerResetByUrl(password: $password, resetUrl: $resetUrl) {
      customer {
        ...CustomerFields
      }
      customerAccessToken {
        ...CustomerAccessTokenFields
      }
      customerUserErrors {
        ...CustomerUserErrorsFields
      }
    }
  }
`

export const GetCustomerAccessToken = gql`
  mutation GetCustomerAccessToken($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      userErrors {
        field
        message
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
    }
  }
`

export const DeleteCustomerAccessToken = gql`
  mutation DeleteCustomerAccessToken($customerAccessToken: String!) {
    customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
      deletedAccessToken
      deletedCustomerAccessTokenId
      userErrors {
        field
        message
      }
    }
  }
`

export const AssociateCheckout = gql`
  ${CheckoutUserErrorsFields}
  mutation AssociateCheckout($checkoutId: ID!, $customerAccessToken: String!) {
    checkoutCustomerAssociateV2(
      checkoutId: $checkoutId
      customerAccessToken: $customerAccessToken
    ) {
      checkout {
        id
      }
      checkoutUserErrors {
        ...CheckoutUserErrorsFields
      }
      customer {
        id
      }
    }
  }
`

export const CustomerAddressCreate = gql`
  ${CustomerUserErrorsFields}
  mutation CustomerAddressCreate(
    $customerAccessToken: String!
    $address: MailingAddressInput!
  ) {
    customerAddressCreate(
      customerAccessToken: $customerAccessToken
      address: $address
    ) {
      customerAddress {
        id
      }
      customerUserErrors {
        ...CustomerUserErrorsFields
      }
    }
  }
`

export const CustomerAddressUpdate = gql`
  ${CustomerUserErrorsFields}
  mutation CustomerAddressUpdate(
    $customerAccessToken: String!
    $id: ID!
    $address: MailingAddressInput!
  ) {
    customerAddressUpdate(
      customerAccessToken: $customerAccessToken
      id: $id
      address: $address
    ) {
      customerAddress {
        id
      }
      customerUserErrors {
        ...CustomerUserErrorsFields
      }
    }
  }
`

export const CustomerAddressDelete = gql`
  ${CustomerUserErrorsFields}
  mutation CustomerAddressDelete($id: ID!, $customerAccessToken: String!) {
    customerAddressDelete(id: $id, customerAccessToken: $customerAccessToken) {
      customerUserErrors {
        ...CustomerUserErrorsFields
      }
      deletedCustomerAddressId
    }
  }
`

export const CustomerDefaultAddressUpdate = gql`
  ${CustomerUserErrorsFields}
  mutation CustomerDefaultAddressUpdate(
    $customerAccessToken: String!
    $addressId: ID!
  ) {
    customerDefaultAddressUpdate(
      customerAccessToken: $customerAccessToken
      addressId: $addressId
    ) {
      customer {
        id
      }
      customerUserErrors {
        ...CustomerUserErrorsFields
      }
    }
  }
`
